type Color = {
  id: string;
  name: string;
  hex: string;
  red: string;
  green: string;
  blue: string;
};

export enum SleepConciergeTypes {
  SLEEPING_ESSENTIALS = "sleepingEssentials",
  WHITE_NOISE_FOR_BETTER_SLEEP = "whiteNoiseForBetterSleep",
  CALMING_MUSIC = "calmingMusic",
  CHAMOMILE_TEA = "chamomileTea",
  TURN_OFF_LATER = "turnOffLater",
}

enum SleepConciergeSubDataTypes {
  REQUEST = "Request",
  RADIO = "Radio",
  MENU = "Menu",
  TOGGLE = "Toggle",
  SELECT_TIME = "SelectTime",
}

export interface ISleepingEssentialsSubData {
  id: string;
  name: string;
  qty: number;
  type: SleepConciergeSubDataTypes;
  selected: boolean;
}

export interface ISleepingEssentials {
  show: boolean;
  title: string;
  type: SleepConciergeTypes.SLEEPING_ESSENTIALS;
  viewType: SleepConciergeSubDataTypes;
  subData: Array<ISleepingEssentialsSubData>;
}

export interface IWhiteNoiseSubData
  extends Omit<ISleepingEssentialsSubData, "qty"> {
  value: string;
  defaultPlaying: boolean;
}

export type IDefaultOnOff = "on" | "off";

export type IMusicTime = {
  name: string;
  time: string;
  type: SleepConciergeSubDataTypes.SELECT_TIME;
};

export interface IWhiteNoise {
  defaultOnOff: IDefaultOnOff;
  show: boolean;
  title: string;
  type: SleepConciergeTypes.WHITE_NOISE_FOR_BETTER_SLEEP;
  viewType: SleepConciergeSubDataTypes;
  subData: Array<IWhiteNoiseSubData>;
  time: Array<IMusicTime>;
}

export interface ICalmingMusicSubData extends IWhiteNoiseSubData {}

export interface ICalmingMusic extends Omit<IWhiteNoise, "type"> {
  type: SleepConciergeTypes.CALMING_MUSIC;
  subData: Array<ICalmingMusicSubData>;
}

export interface IChamomileTeaSubData extends IWhiteNoiseSubData {
  department: string;
  departmentName: string;
}

export interface IChamomileTea
  extends Omit<IWhiteNoise, "defaultOnOff" | "type"> {
  type: SleepConciergeTypes.CHAMOMILE_TEA;
  subData: Array<IChamomileTeaSubData>;
}

export interface ITVOffTimerSubData extends IWhiteNoiseSubData {
  schedule: string;
  time: number;
}

export interface ITVOffTimer
  extends Omit<IWhiteNoise, "defaultOnOff" | "type" | "time"> {
  type: SleepConciergeTypes.TURN_OFF_LATER;
  subData: Array<ITVOffTimerSubData>;
}

export interface ISounds extends IWhiteNoiseSubData {
  genre: string;
  thumbnail: string;
  url: string;
}

export interface ITemperatureSettings {
  fanSpeed: {
    show: boolean;
    defaultSpeed: string;
  };
  temperature: {
    show: boolean;
    defaultTemperature: number;
    minTemperature: number;
    maxTemperature: number;
  };
  temperatureType: {
    show: boolean;
    defaultTemperatureType: string;
  };
}

export type ISleepSetting =
  | ISleepingEssentials
  | IWhiteNoise
  | ICalmingMusic
  | IChamomileTea
  | ITVOffTimer;

export interface IAlarmSettings {
  show: boolean;
  volume: string;
  subData: Array<{
    id: string;
    isEnabled: boolean;
    isSelected: boolean;
    schedule: string;
  }>;
}

export interface IWifiSettings {
  show: boolean;
  image: string;
}

export interface ICastSettings {
  show: boolean;
  image: string;
  description: string;
}

export interface IMusicSettings {
  show: boolean;
  subData: ISounds[];
}

type IOldSubCategory = {
  image: string;
  description: string;
  alarmSetting: Array<{
    id: string;
    isEnabled: boolean;
    isSelected: boolean;
    schedule: string;
  }>;
};

export type ISubCategory = {
  parentKey: string;
  categoryName: string;
  displayName: string;
  lightColors: {
    showAdditionalColors: boolean;
    additionalColors: Color[];
  };
  temperatureSettings: ITemperatureSettings;
  sleepSetting: Array<ISleepSetting>;
  alarmSettings: IAlarmSettings;
  wifiSettings: IWifiSettings;
  castSettings: ICastSettings;
  musicSettings: IMusicSettings;
  sounds: ISounds[];
} & IOldSubCategory;

export interface ICategoryComponentProps<T = ISubCategory> {
  data: T;
  onSave: (data: { categoryName: string; data: T } | FormData) => void;
  loading: boolean;
}
export interface ISleepCategoryComponentProps<T> {
  subData: T;
  show: boolean;
  categoryName: string;
  type: string;
  onSave: (data: Partial<ISleepSetting> | FormData) => void;
  loading: boolean;
  time?: IMusicTime[];
  defaultOnOff?: IDefaultOnOff;
}

export interface IOffer {
  _id: string;
  hotelId: string;
  name: string;
  startDate: string;
  endDate: string;
  show: boolean;
  description: string;
  redemptionDescription: string;
  costCenter: string;
  costSubCenter: string;
  type: string;
  value: number;
  cost: number;
  businessReason: string;
  eligibilityCriterias: Array<{
    criteriaId: string;
    values: string[];
  }>;
  redemptionLocation: string;
  startTime: string;
  endTime: string;
  tvSetup: {
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    image: string | null;
  };
}