import { useRef } from "react";
import Switch from "../../../components/common/Switch";
import { CloseSvg } from "../../../icons";
import DiscardSaveButtons from "../../../components/tv-app/discard-save-buttons";
import { ICategoryComponentProps } from "../../../utils/types";
import { FormProvider, useForm } from "react-hook-form";

interface IFormData {
  showWifi: boolean;
  image: File | string;
}

const WiFi = ({ data, onSave, loading }: ICategoryComponentProps) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const getInitialFormData = ()=>({
    showWifi:!!data?.wifiSettings?.show,
    image: data?.wifiSettings?.image || data?.image || ""
  })

  const methods = useForm<IFormData>({defaultValues : getInitialFormData()})

  const {setValue , watch , handleSubmit, reset} = methods

  const {image} = watch()

  return (
    <FormProvider {...methods}>
    <div>
      <h3 className="text-[22px] font-normal text-[#1E2F65]">WiFi</h3>
      <div className="">
        <Switch
          label="WiFi (Hide/Show)"
          name="showWifi"
        />
      </div>
      <div className="flex items-center gap-2.5">
        <input
          ref={inputFileRef}
          type="file"
          accept="image/*"
          className="block w-full text-[10px] leading-[18px] text-[#666672] border border-[#D9D9D9] rounded cursor-pointer focus:outline-none file:bg-[#F41095] file:box-border file:p-2 file:text-[10px] file:leading-[18px] file:outline-none file:border-none file:border-r file:border-[#D9D9D9] file:text-white"
          // className="w-full h-[33px] flex items-center justify-center text-[10px] leading-[18px] text-[#666672] border border-[#D9D9D9] rounded cursor-pointer focus:outline-none file:h-full file:bg-[#F41095] file:box-border file:text-[10px] file:outline-none file:border-none file:border-r file:border-[#D9D9D9] file:text-white"
          onChange={(e) => {
            if (e.target.files!?.[0]) setValue('image',e.target.files!?.[0]);
          }}
        />
        <CloseSvg
          height={25}
          width={25}
          className="cursor-pointer"
          onClick={() => {
            setValue('image','');
            inputFileRef.current!.value = "";
          }}
        />
      </div>
      <div className="mt-7 flex items-center justify-center">
        <img
          src={typeof image === "string" ? image : URL.createObjectURL(image)}
          height={240}
          width={240}
        />
      </div>

      <DiscardSaveButtons
        onDiscard={() => {
          reset(getInitialFormData())
          inputFileRef.current!.value = "";
        }}
        onSave={handleSubmit((submitData : IFormData) => {
          const formData = new FormData();
          if (typeof submitData.image !== "string") formData.append("image", submitData.image);
          const updatedData = {
            ...data,
            wifiSettings: { ...(data?.wifiSettings || {}), show: submitData.showWifi },
          };
          formData.append("categoryName", updatedData.categoryName);
          formData.append("data", JSON.stringify({ ...updatedData }));
          onSave(formData);
        })}
        loading={loading}
        className="mt-10"
      />
    </div>
    </FormProvider>
  );
};

export default WiFi;
