import TVApp from "../pages/tv-app";
import Casting from "../pages/tv-app/entertainment/casting";
import FreeStreaming from "../pages/tv-app/entertainment/free-streaming";
import Music from "../pages/tv-app/entertainment/music";
import MyApps from "../pages/tv-app/entertainment/my-apps";
import WiFi from "../pages/tv-app/entertainment/wifi";
import Alarm from "../pages/tv-app/room/alarm";
import Light from "../pages/tv-app/room/light";
import SleepConcierge from "../pages/tv-app/room/sleep-concierge";
import Temperature from "../pages/tv-app/room/temperature";
import Offers from "../pages/tv-app/offers/offers";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/tv-app/*`,
    Component: <TVApp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mobile-app`,
    Component: <h1>Comming Soon</h1>,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-users`,
    Component: <h1>Comming Soon</h1>,
  },
  {
    path: `${process.env.PUBLIC_URL}/global-setting`,
    Component: <h1>Comming Soon</h1>,
  },
];

export const tvAppRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/room/lights`,
    Component: Light,
  },
  {
    path: `${process.env.PUBLIC_URL}/room/temperature`,
    Component: Temperature,
  },
  {
    path: `${process.env.PUBLIC_URL}/room/sleep`,
    Component: SleepConcierge,
  },
  {
    path: `${process.env.PUBLIC_URL}/room/alarm`,
    Component: Alarm,
  },
  {
    path: `${process.env.PUBLIC_URL}/entertainment/wifi`,
    Component: WiFi,
  },
  {
    path: `${process.env.PUBLIC_URL}/entertainment/casting`,
    Component: Casting,
  },
  {
    path: `${process.env.PUBLIC_URL}/entertainment/livetv`,
    Component: FreeStreaming,
  },
  {
    path: `${process.env.PUBLIC_URL}/entertainment/apps`,
    Component: MyApps,
  },
  {
    path: `${process.env.PUBLIC_URL}/entertainment/music`,
    Component: Music,
  },
  {
    path: `${process.env.PUBLIC_URL}/offers/offers`,
    Component: Offers,
  }
];
