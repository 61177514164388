import { useMutation } from "@tanstack/react-query";
import { AXIOS_HOSPITALITY } from "../utils/axios-interceptors";
import { IUseAdminLoginMutationResponse } from "../utils/types";

const useAdminLoginMutation = (): IUseAdminLoginMutationResponse =>
  useMutation({
    mutationFn: async (data) => {
      const response = await AXIOS_HOSPITALITY.post("adminLogin", data);
      return response.data;
    },
  });

export default useAdminLoginMutation;
