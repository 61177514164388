import { useEffect, useState } from "react";
import Switch from "../../../components/common/Switch";
import Select from "../../../components/common/Select";
import Button from "../../../components/common/button";
import DataTable from "react-data-table-component";
import { VOLUME_OPTIONS } from "../../../utils/constants";
import AddAlarmMusicModal from "../../../components/tv-app/alarm/add-alarm-music-modal";
import DeleteAndroidHome from "../../../components/tv-app/delete-android-home";
import EditTableRowName from "../../../components/tv-app/edit-name";
import DiscardSaveButtons from "../../../components/tv-app/discard-save-buttons";
import { ICategoryComponentProps, ISounds, ITableColumn } from "../../../utils/types";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

type IFormData = {
  showAlarm: boolean;
  soundsData: ISounds[];
  volume: { label: string; value: string };
};

const Alarm = ({ data, loading, onSave }: ICategoryComponentProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const getInitialFormData = () => {
    return {
      showAlarm: !!data?.alarmSettings?.show,
      soundsData: data?.sounds || [],
      volume:
        VOLUME_OPTIONS.find(
          (item) => item.value === data?.alarmSettings?.volume
        ) || VOLUME_OPTIONS[0],
    };
  };

  const [InitialFormData, setInitialFormData] = useState<IFormData>(
    getInitialFormData()
  );

  const methods = useForm<IFormData>({ defaultValues: InitialFormData });
  const { reset, handleSubmit } = methods;

  const {
    fields: alarmFields,
    append: appendAlarmField,
    remove: removeAlarmField,
  } = useFieldArray({
    name: "soundsData",
    control: methods.control,
    keyName: "customId",
  });

  useEffect(() => {
    // setSoundsData(JSON.parse(JSON.stringify(data?.sounds)));
    if (alarmFields.length < data.sounds.length) {
      const addedElement = data.sounds[data.sounds.length - 1];
      appendAlarmField({ ...addedElement });
      setInitialFormData((pre) => ({
        ...pre,
        soundsData: [...pre.soundsData, { ...addedElement }],
      }));
    }
  }, [data.sounds]);

  const getTableDataIndex = (id: string) => {
    return alarmFields.findIndex((item) => item.id === id);
  };

  const tableColumns: ITableColumn<ISounds>[] = [
    {
      name: "Name",
      cell: (Row) => (
        <EditTableRowName
          name={`soundsData.${getTableDataIndex(Row.id)}.name`}
        />
      ),
      sortable: true,
      width: "40%",
    },
    {
      name: "",
      cell: (Row) => <span></span>,
      width: "20%",
    },
    {
      name: "View",
      cell: (Row) => (
        <Switch name={`soundsData.${getTableDataIndex(Row.id)}.selected`} />
      ),
      sortable: true,
      center: true,
      width: "20%",
    },
    {
      name: "Status",
      cell: (Row) => (
        <DeleteAndroidHome
          categoryName={data?.categoryName}
          deleteId={Row.id}
          removeField={() => {
            setInitialFormData((pre) => ({
              ...pre,
              soundsData: pre.soundsData.filter((item) => item.id !== Row.id),
            }));
            removeAlarmField(getTableDataIndex(Row.id));
          }}
        />
      ),
      sortable: true,
      center: true,
      width: "20%",
    },
  ];

  return (
    <FormProvider {...methods}>
      <div>
        <h3 className="text-[22px] font-normal text-[#1E2F65]">Alarm</h3>
        <div className="">
          <Switch name="showAlarm" label="Alarm (Hide/Show)" />
        </div>
        <div className="grid grid-cols-3 gap-2 relative">
          <Select
            label="Select Volume"
            options={VOLUME_OPTIONS}
            name="volume"
          />
        </div>
        <div className="flex items-center justify-between w-full mt-7.5">
          <h4 className="text-base leading-[39px] font-normal text-[#1E2F65]">
            Music List
          </h4>
          <Button variant="primary" className="w-21" onClick={openModal}>
            Add
          </Button>
        </div>
        {/* <div className="h-full max-h-[calc(100vh-520px)] overflow-auto mt-[17px]"> */}
        <DataTable
          className="border-t mt-6"
          data={alarmFields}
          columns={tableColumns}
          // striped={true}
          keyField="customId"
          persistTableHead
          pagination={alarmFields.length > 10}
          customStyles={{
            headRow: { style: { paddingLeft: "36px" } },
            rows: { style: { paddingLeft: "36px" } },
          }}
        />
        {/* </div> */}

        <DiscardSaveButtons
          onDiscard={() => {
            reset(getInitialFormData());
          }}
          onSave={handleSubmit((submitData: IFormData) =>
            onSave({
              categoryName: data?.categoryName,
              data: {
                ...data,
                alarmSettings:
                  "show" in (data?.alarmSettings || {})
                    ? {
                        ...data.alarmSettings,
                        volume: submitData.volume.value,
                        show: submitData.showAlarm,
                      }
                    : {
                        show: submitData.showAlarm,
                        volume: submitData.volume.value,
                        subData: data.alarmSetting,
                      },
                sounds: submitData.soundsData,
              },
            })
          )}
          loading={loading}
          className="mt-10"
        />

        <AddAlarmMusicModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          categoryName={data.categoryName}
        />
      </div>
    </FormProvider>
  );
};

export default Alarm;
