import classNames from "classnames";
import React from "react";
import Spinner from "../Spinner";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "default" | "primary";
  loading?: boolean;
}

const Button = ({
  className,
  children,
  variant = "default",
  loading,
  ...rest
}: IButtonProps) => {
  return (
    <button
      className={classNames(
        "px-2.5 py-2 text-sm leading-[21px] font-normal flex items-center justify-center gap-2",
        variant === "primary"
          ? "bg-[#F41095] text-white"
          : "bg-[#F4F4F4] text-black",
        rest.disabled ? "opacity-50" : "",
        className
      )}
      {...rest}
    >
      {loading && <Spinner className="border-white h-4 w-4" />}
      {children}
    </button>
  );
};

export default Button;
