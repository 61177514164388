import classNames from "classnames";
import { PropsWithChildren } from "react";

interface ICardProps {
  className?: string;
}

const Card = ({ children, className }: PropsWithChildren<ICardProps>) => {
  return (
    <div
      className={classNames(
        "bg-white h-full w-full p-6 shadow-[0px_4px_30px_0px_#5F5EE712]",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
