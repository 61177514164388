import { PropsWithChildren } from "react";
import { Navigate, useParams } from "react-router";

const PrivateRouteCheck = ({ children }: PropsWithChildren) => {
  const params = useParams();
  const jwtToken = localStorage.getItem("token");

  if (!jwtToken) {
    return (
      <Navigate to={`${process.env.PUBLIC_URL}/${params.hotelcode}/login`} />
    );
  }

  return children;
};

export default PrivateRouteCheck;
