import { ReactNode } from "react";
import ReactSelect, { ActionMeta, Props } from "react-select";
import Option from "../MultiSelectOptionsWithCheckBox";
import {
  FieldError,
  RegisterOptions,
  useController,
  UseFormRegister,
} from "react-hook-form";
import { getNestedError } from "../../../utils/helpers";

interface ISelectProps extends Omit<Props, "onChange"> {
  label?: string | ReactNode;
  onChange?: (newValue: any, actionMeta: ActionMeta<unknown>) => void;
  wrapperComponentStyle?: string;
  checkboxOptions?: boolean;
  register?: UseFormRegister<any>;
  error?: FieldError;
  validation?: RegisterOptions;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

export type ISelectOptionType = { label: string; value: string | number };

const Select = ({
  label,
  wrapperComponentStyle,
  checkboxOptions,
  register,
  validation,
  error,
  onChange,
  name,
  rules,
  ...rest
}: ISelectProps) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules });

  return (
    <div className={wrapperComponentStyle}>
      {label ? (
        typeof label === "string" ? (
          <label className="text-sm leading-[21px] placeholder-placeholder font-normal text-[#666672]">
            {label}
          </label>
        ) : (
          label
        )
      ) : null}
      <ReactSelect
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? "none" : base.borderColor,
            boxShadow: "none",
            ":hover": {
              borderColor: state.isFocused ? "#66667280" : base.borderColor,
            },
            fontSize: "14px",
          }),
          singleValue: (base) => ({
            ...base,
          }),
          placeholder: (base) => ({
            ...base,
            color: "#66667280",
            fontSize: "14px",
          }),

          option: (base, state) => ({
            ...base,
            color: state.isSelected ? "#F41095" : "#666672",
            backgroundColor: state.isSelected
              ? "#FEE7F4"
              : state.isFocused
              ? base.backgroundClip
              : base.backgroundColor,
            ":hover": {
              backgroundColor: "#FEE7F4",
            },
          }),
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#F41095",
            backgroundColor: "#FEE7F4",
            width: "fit-contnet",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#F41095",
            backgroundColor: "#FEE7F4",
            ":hover": {
              backgroundColor: "#FEE7F4",
            },
          }),
        }}
        {...field}
        {...rest}
        onChange={(data, actionMeta) => {
          onChange?.(data, actionMeta);
          field.onChange({ target: { value: data } });
        }}
        {...(rest.isMulti && checkboxOptions
          ? {
              components: { Option: Option },
            }
          : {})}
      />
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default Select;
