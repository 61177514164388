import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { queryClient } from "../App";
import { IUseAddAndroidTVHomeMutationResponse } from "../utils/types";

const useAddAndroidTVHome = (): IUseAddAndroidTVHomeMutationResponse =>
  useMutation({
    mutationFn: async (data) => {
      const response = await AXIOS_RESERVATION.post(
        "android-tv-home/add-data",
        data
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["categories"] });
    },
  });

export default useAddAndroidTVHome;
