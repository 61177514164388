import { FormProvider, useForm } from "react-hook-form";
import Modal from "../../common/Modal";
import TextInput from "../../common/TextInput";
import Switch from "../../common/Switch";
import Button from "../../common/button";
import useAddAndroidTVHome from "../../../hooks/use-add-android-home";
import { toast } from "react-toastify";

interface IFormData {
  time: number | undefined;
  selected: boolean;
}

const AddTVOffTimerModal = ({
  isOpen,
  closeModal,
  categoryName,
  type,
}: {
  isOpen: boolean;
  closeModal: () => void;
  categoryName: string;
  type: string;
}) => {
  const { mutate: addAndroidTVHome, isPending: addAndroidTVHomeLoading } =
    useAddAndroidTVHome();

  const methods = useForm<IFormData>({ defaultValues: { selected: false } });
  const { handleSubmit, reset } = methods;

  const handleClose = () => {
    reset({ selected: false, time: undefined });
    closeModal();
  };

  const onSubmit = (data: IFormData) => {
    addAndroidTVHome(
      { categoryName, type, data },
      {
        onSuccess: (res) => {
          toast.success(res.result.message);
          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleClose}
      title="Add TV Off Timer"
      className="w-144.75"
    >
      <FormProvider {...methods}>
        <TextInput
          type="number"
          label="Minute"
          name="time"
          min={0}
          max={100}
          rules={{
            required: "Minute is required",
          }}
        />
        <div className="mt-7.5">
          <Switch
            name="selected"
            label="Enable on TV App"
          />
        </div>
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={handleClose}>
            Discard
          </Button>
          <Button
            className="w-25.5"
            variant="primary"
            loading={addAndroidTVHomeLoading}
            disabled={addAndroidTVHomeLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default AddTVOffTimerModal;
