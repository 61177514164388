import React, {
  useState,
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  ReactNode,
} from "react";
import classNames from "classnames";

const AccordionContext = createContext<{
  activeId?: string | number;
  toggleAccordion?: (id: string) => void;
}>({
  activeId: undefined,
  toggleAccordion: undefined,
});

type AccordianProps = PropsWithChildren<{
  defaultActiveId?: string;
}>;

const Accordian = ({ children, defaultActiveId }: AccordianProps) => {
  const [activeId, setActiveId] = useState<string | undefined>(defaultActiveId);

  const toggleAccordion = (id: string) => {
    setActiveId((prev) => (prev === id ? null : id) as string);
  };

  return (
    <AccordionContext.Provider value={{ activeId, toggleAccordion }}>
      <div>{children}</div>
    </AccordionContext.Provider>
  );
};

export default Accordian;

type AccordianItemProps = {
  id: number | string;
};

export const AccordianItem = ({
  id,
  children,
}: PropsWithChildren<AccordianItemProps>) => {
  const { activeId, toggleAccordion } = useContext(AccordionContext);
  const isActive = activeId === id;
  const ref = useRef();

  return (
    <div className="w-full">
      {/* @ts-ignore */}
      {children!?.({ isActive, toggleAccordion, ref })}
    </div>
  );
};

type AccordianHeaderProps = {
  id: string;
  children: React.ReactNode;
  icon?: ReactNode;
};

export const AccordianHeader = ({
  id,
  children,
  icon,
}: AccordianHeaderProps) => {
  const { activeId, toggleAccordion } = useContext(AccordionContext);

  const downSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      className="w-4 h-4"
    >
      <path
        fillRule="evenodd"
        d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );

  const upSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      className="w-4 h-4"
    >
      <path
        fillRule="evenodd"
        d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );

  return (
    <button
      onClick={() => toggleAccordion?.(id)}
      className={classNames(
        "w-full h-[39px] rounded-[3px] flex justify-between items-center pl-[23px] pr-4",
        activeId === id ? "text-[#F41095] bg-[#F410951A]" : "bg-[#F9F9F9]"
      )}
    >
      <span>{children}</span>
      <span className="text-slate-800 transition-transform duration-300">
        {!icon ? (activeId === id ? upSVG : downSVG) : icon}
      </span>
    </button>
  );
};

type AccordianContentProps = {
  id: number | string;
  className?: string;
};

export const AccordianContent = ({
  id,
  children,
  className,
}: PropsWithChildren<AccordianContentProps>) => {
  const { activeId } = useContext(AccordionContext);
  const isActive = activeId === id;

  return (
    <div
      id={`accordian-${id}`}
      className={classNames(
        "overflow-hidden transition-all duration-700 ease-in-out",
        isActive ? "max-h-[500px]" : "max-h-0",
        className
      )}
      onDrag={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {isActive && <div>{children}</div>}
    </div>
  );
};
