import React from "react";
import classNames from "classnames";
import { IconType } from "react-icons";
import { FieldError, RegisterOptions, useController } from "react-hook-form";
import { getNestedError } from "../../../utils/helpers";

interface ITimeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon?: IconType;
  iconClassName?: string;
  label?: string;
  labelClassName?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const TimeInput: React.FC<ITimeInputProps> = ({
  label,
  labelClassName,
  Icon,
  iconClassName,
  rules,
  ...rest
}) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: rest.name || "", rules });

  return (
    <div>
      {label && (
        <label
          className={classNames("block text-sm text-[#666672]", labelClassName)}
        >
          {label}
        </label>
      )}
      <div className={classNames("relative flex", label ? "mt-1" : "")}>
        {/* {Icon && (
          <span className={classNames("absolute top-3 left-3", iconClassName)}>
            <Icon />
          </span>
        )} */}

        <input
          type="time"
          className={classNames(
            "w-full p-2 border text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400 placeholder:text-[#666672]"
          )}
          {...field}
          {...rest}
        />
      </div>
      {errors && rest.name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, rest.name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default TimeInput;
