import classNames from "classnames";
import { FieldError, RegisterOptions, useController } from "react-hook-form";
import { IconType } from "react-icons";
import { getNestedError } from "../../../utils/helpers";

interface ITextAreaProps
  extends React.InputHTMLAttributes<
    Omit<HTMLTextAreaElement, "value" | "onChange">
  > {
  label?: string;
  Icon?: IconType;
  error?: FieldError;
  validation?: RegisterOptions;
  labelClassName?: string;
  iconClassName?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}
const TextArea: React.FC<ITextAreaProps> = ({
  label,
  name,
  labelClassName,
  placeholder,
  Icon,
  error,
  validation,
  iconClassName,
  value,
  rules,
  ...rest
}) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules });
  return (
    <div>
      {label && (
        <label
          className={classNames("block text-sm text-[#666672]", labelClassName)}
        >
          {label}
        </label>
      )}
      <div className={classNames("relative flex", label ? "mt-1" : "")}>
        {Icon && (
          <span>
            <Icon
              className={classNames("absolute top-3 left-3", iconClassName)}
            />
          </span>
        )}
        <textarea
          placeholder={placeholder}
          {...field}
          {...rest}
          onChange={(e) => {
            field.onChange(e);
            rest?.onChange?.(e);
          }}
        />
      </div>
      {error && <span className="text-red-500 text-sm">{error.message}</span>}
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};
export default TextArea;
