import classNames from "classnames";
import React, { useState, DragEvent, ChangeEvent } from "react";
import { formatBytes, getNestedError } from "../../../utils/helpers";
import { CloseSvg } from "../../../icons";
import { FieldError, RegisterOptions, useController } from "react-hook-form";

type IFileDropZonProps = {
  label?: string;
  onSelectOrDrop?: (files: FileList) => void;
  onClear?: () => void;
  accept?: string;
  name?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
};

const FileDropZone = ({
  label,
  onSelectOrDrop,
  onClear,
  accept,
  name,
  rules,
}: IFileDropZonProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules });
  const { ref, value, onChange, ...otherFieldValue } = field;

  // Handle drag over event
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Handle drag leave event
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // Handle file drop event
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...Array.from(droppedFiles)]);
      onChange(droppedFiles[0]);
      // onSelectOrDrop!?.(droppedFiles);
    }
  };

  // Handle file selection via the input element
  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...Array.from(selectedFiles)]);
      onChange(selectedFiles[0]);
      // onSelectOrDrop!?.(selectedFiles);
    }
  };

  // Trigger file input click programmatically
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      {label && (
        <label className="text-sm leading-[18.03px] font-normal text-[#666672]">
          {label}
        </label>
      )}
      <div
        className={classNames(
          "w-full h-28 border-[0.86px] border-dashed rounded flex flex-col justify-center items-center text-xl text-gray-600 transition-colors cursor-pointer",
          label ? "mt-2.5" : "",
          isDragging
            ? "bg-secondary/5 border-secondary"
            : "bg-gray-[#D9D9D933] border-gray-400"
        )}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <p className="text-sm leading-[18.03px] font-normal text-[#666672]">
          {isDragging ? (
            "Release to drop files"
          ) : files.length ? (
            <div className="flex items-center gap-5">
              <div>
                {files[0].name}, {formatBytes(files[0].size)}
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  fileInputRef.current!.files = null;
                  setFiles([]);
                  onClear!?.();
                }}
              >
                <CloseSvg className="text-[#1D1B20]" />
              </div>
            </div>
          ) : (
            "Drag Files or Click to Browse"
          )}
        </p>

        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          multiple
          onChange={handleFileInputChange}
          accept={accept}
          {...otherFieldValue}
        />
      </div>
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default FileDropZone;
