import { ReactNode } from "react";
import { useTabsContext } from ".";

type TabPanelProps = {
  value: string;
  children: ReactNode;
};

const TabPanel = ({ value, children }: TabPanelProps) => {
  const { activeTab } = useTabsContext();

  return activeTab === value ? <div>{children}</div> : null;
};

export default TabPanel;
