import { FormProvider, useForm } from "react-hook-form";
import Switch from "../../../components/common/Switch";
import DiscardSaveButtons from "../../../components/tv-app/discard-save-buttons";
import { ICategoryComponentProps } from "../../../utils/types";

const APPS = [
  { id: 1, image: "/images/free-apps/clear-cred.png" },
  { id: 2, image: "/images/free-apps/netflix.png" },
  { id: 3, image: "/images/free-apps/apple-tv.png" },
  { id: 4, image: "/images/free-apps/hulu.png" },
  { id: 5, image: "/images/free-apps/amazon-prime.png" },
  { id: 6, image: "/images/free-apps/yt-premium.png" },
  { id: 7, image: "/images/free-apps/tubi.png" },
  { id: 8, image: "/images/free-apps/max.png" },
  { id: 9, image: "/images/free-apps/disney-plus.png" },
];

const FreeStreaming = ({}: ICategoryComponentProps) => {
  const methods = useForm()
  return (
    <FormProvider {...methods}>
    <div>
      <h3 className="text-[22px] font-normal text-[#1E2F65]">Free Streaming</h3>
      <div className="">
        <Switch label="Free Streaming (Hide/Show)" checked={true} />
      </div>
      <p className="text-xs leading-[39px] font-light text-[#1E2F65]">
        Note : Drag and adjust the numbering to change the order
      </p>
      <div className="flex items-center flex-wrap gap-x-[14px] gap-y-[17px]">
        {APPS.map((app) => (
          <img key={app.id} src={app.image} />
        ))}
      </div>

      <DiscardSaveButtons
        onDiscard={() => {}}
        onSave={() => {}}
        loading={false}
        className="mt-10"
      />
    </div>
    </FormProvider>
  );
};

export default FreeStreaming;
