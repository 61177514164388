import { useState } from "react";
import Switch from "../../../../components/common/Switch";
import { TableColumn } from "react-data-table-component";
import DataTable from "react-data-table-component";
import useGetOfferQuery from "../../../../hooks/use-get-offers";
import { TrashSvg } from "../../../../icons";
import Modal from "../../../../components/common/Modal";
import Button from "../../../../components/common/button";
import EditOffer from "../../../../components/common/offer/edit-offer";
import useDeleteOffer from "../../../../hooks/use-delete-offer";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IOffer } from "../../../../utils/types";
import useDeleteOfferAttachment from "../../../../hooks/use-delete-attachment";

const CurrentOffer = () => {
  const methods = useForm<{ showOffer: boolean }>({
    defaultValues: { showOffer: false },
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDetachModalOpen, setIsDetachModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDetachModal = () => {
    setIsDetachModalOpen(true);
  };
  const closeDetachModal = () => {
    setIsDetachModalOpen(false);
  };

  const [id, setId] = useState<string>("");
  const { data: offersData } = useGetOfferQuery();
  const { mutate: deleteOffer, isPending: deleteOfferLoading } =
    useDeleteOffer();
  const {
    mutate: deleteAttachmentOffer,
    isPending: deleteAttachmentOfferLoading,
  } = useDeleteOfferAttachment();

  const offerRows: IOffer[] = offersData?.result?.data || [];

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    if (isNaN(date.getTime())) {
      return "-";
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isUSTimeZone = userTimeZone.startsWith("America/");

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: isUSTimeZone ? "America/New_York" : "Asia/Kolkata",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    return formattedDate;
  };
  
  const handleDelete = (id: string) => {
    deleteOffer(
      { id },
      {
        onSuccess: (res) => {
          toast.success(res?.result?.message);
          closeModal();
        },
        onError: (error) => {
          closeModal();
          const message =
            error.response?.data.result.message ||
            "An unexpected error occurred";
          setErrorMessage(message);
          openDetachModal();
        },
      }
    );
  };

  const handleDetachOffer = (id: string) => {
    deleteAttachmentOffer(
      { id },
      {
        onSuccess: (res) => {
          toast.success(res?.result?.message);
          closeDetachModal();
        },
        onError: (error) => {
          closeDetachModal();

          toast.error(error.response?.data.result.message);
        },
      }
    );
  };

  const tableColumns: Array<TableColumn<IOffer>> = [
    {
      name: "Name",
      selector: (Row) => Row.name,
      sortable: true,
      width: "40%",
    },
    {
      name: "Start Date",
      selector: (Row: IOffer) => formatDate(Row.startDate),
      sortable: true,
      width: "20%",
    },
    {
      name: "End Date",
      selector: (Row: IOffer) => formatDate(Row.endDate),
      sortable: true,
      width: "20%",
    },
    {
      name: "Status",
      cell: (Row: IOffer) => (
        <div className="flex items-center gap-2">
          <div
            onClick={() => {
              setId(Row._id);
            }}
          >
            <EditOffer data={Row} />
          </div>
          <div
            onClick={() => {
              setId(Row._id);
              openModal();
            }}
            className="h-6 w-6 rounded-[3.63px] bg-[#E5272733] flex items-center justify-center cursor-pointer"
          >
            <TrashSvg color="#E52727" />
          </div>
        </div>
      ),
      sortable: true,
      width: "20%",
    },
  ];
  return (
    <div>
      <FormProvider {...methods}>
        <div className="mt-[31px]">
          <Switch label="Offers (Hide/Show)" name="showOffer" />
        </div>
      </FormProvider>

      <DataTable
        className="border-t mt-6"
        columns={tableColumns}
        striped={true}
        persistTableHead
        pagination={offerRows.length > 10}
        data={offerRows}
        customStyles={{
          headRow: { style: { paddingLeft: "36px" } },
          rows: { style: { paddingLeft: "36px" } },
        }}
      />

      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title="Are you sure you want to delete this offer?"
        className="w-144.75"
      >
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete(id);
            }}
            loading={deleteOfferLoading}
            disabled={deleteOfferLoading}
            className="w-25.5"
            variant="primary"
          >
            Delete
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={isDetachModalOpen}
        closeModal={closeDetachModal}
        title={errorMessage || "Detach this offer?"}
        className="w-144.75"
      >
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={closeDetachModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              openDetachModal();
              handleDetachOffer(id);
            }}
            loading={deleteAttachmentOfferLoading}
            disabled={deleteAttachmentOfferLoading}
            className="w-25.5"
            variant="primary"
          >
            Detach
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CurrentOffer;
