import { components, OptionProps } from 'react-select'
import classNames from "classnames";
import { CheckboxTickSvg } from '../../../icons';

const Option: React.FC<OptionProps> = (props) => {
    return (
        <div>
            <components.Option {...props} className="flex items-center">
                <div className="flex h-full items-center gap-3">
                    <div className="flex relative">
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                            className={classNames("appearance-none h-4 w-4 border-2 rounded-sm", props.isSelected ? "border-[#F41095]" : "border-[#666672]")}
                        />
                        <CheckboxTickSvg
                            stroke="#F41095"
                            className={classNames(
                                "w-3 h-3 text-[#F41095] font-extrabold top-[50%] left-[50%] absolute pointer-events-none -translate-x-[50%] -translate-y-[50%]",
                                props.isSelected ? "block" : "hidden"
                            )}
                        /></div>
                    <label>{props.label}</label>
                </div>
            </components.Option>
        </div>
    );
};

export default Option;