import { Fragment, useEffect, useState } from "react";

type LoaderProps = { isLoading?: boolean };

const Loader = (props: LoaderProps) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);
  return (
    <Fragment>
      <div
        className={`loader-wrapper ${
          show || props.isLoading ? "" : "loaderhide"
        }`}
      >
        <div className="loader">
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-ball"></div>
        </div>
      </div>
    </Fragment>
  );
};
export default Loader;
