import { FormProvider, useForm } from "react-hook-form";
import Modal from "../../common/Modal";
import TextInput from "../../common/TextInput";
import Switch from "../../common/Switch";
import Button from "../../common/button";
import useAddAndroidTVHome from "../../../hooks/use-add-android-home";
import FileDropZone from "../../common/FileDropzon";
import { toast } from "react-toastify";

interface IFormData {
  name: string;
  defaultPlaying: boolean;
  selected: boolean;
  file: File | undefined;
}

const AddWhiteNoiseModal = ({
  isOpen,
  closeModal,
  categoryName,
  type,
}: {
  isOpen: boolean;
  closeModal: () => void;
  categoryName: string;
  type: string;
}) => {
  const { mutate: addAndroidTVHome, isPending: addAndroidTVHomeLoading } =
    useAddAndroidTVHome();

  const methods = useForm<IFormData>();
  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = (data: IFormData) => {
    const formData = new FormData();
    formData.append("categoryName", categoryName);
    formData.append("type", type);
    formData.append("file", data.file!);
    delete data.file;
    formData.append("data", JSON.stringify(data));

    addAndroidTVHome(formData, {
      onSuccess: (res) => {
        toast.success(res.result.message);
        handleClose();
      },
    });
  };

  const handleClose = () => {
    reset();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleClose}
      title="Add White Noise Music"
      className="w-144.75"
    >
      <FormProvider {...methods}>
        <TextInput
          label="Music Name"
          name="name"
          rules={{
            required: "Name is required",
          }}
        />
        <div className="flex items-center gap-16 mt-6.5">
          <Switch label="Default Playing" name="defaultPlaying" />
          <Switch label="Enable on TV App" name="selected" />
        </div>
        <div className="mt-11.25">
          <FileDropZone
            label="Upload Music"
            // onSelectOrDrop={(files) => setValue("file", files[0])}
            name="file"
            rules={{ required: "Music file is required" }}
            onClear={() => setValue("file", undefined)}
            accept=".mp3, .wav, .ogg"
          />
        </div>
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={handleClose}>
            Discard
          </Button>
          <Button
            className="w-25.5"
            variant="primary"
            loading={addAndroidTVHomeLoading}
            disabled={addAndroidTVHomeLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default AddWhiteNoiseModal;
