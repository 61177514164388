import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { queryClient } from "../App";
import { IUseDeleteOfferAttachmentMutationResponse } from "../utils/types";

const useDeleteOfferAttachment =
  (): IUseDeleteOfferAttachmentMutationResponse =>
    useMutation({
      mutationFn: async (data: any) => {
        const response = await AXIOS_RESERVATION.delete(
          `v1/coupons/${data.id}/detach`
        );
        return response.data;
      },
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: ["offers"] });
      },
    });

export default useDeleteOfferAttachment;
