import { FormProvider, useForm } from "react-hook-form";
import Modal from "../../common/Modal";
import TextInput from "../../common/TextInput";
import Select from "../../common/Select";
import Switch from "../../common/Switch";
import Button from "../../common/button";
import { GENRE_OPTIONS } from "../../../utils/constants";
import useAddAndroidTVHome from "../../../hooks/use-add-android-home";
import FileDropZone from "../../common/FileDropzon";
import { toast } from "react-toastify";

interface IFormData {
  name: string;
  genre: { label: string; value: string } | null;
  selected: boolean;
  file: File | undefined;
  thumbnail: File | undefined;
}

const AddAlarmMusicModal = ({
  isOpen,
  closeModal,
  categoryName,
  modalTitle,
}: {
  isOpen: boolean;
  closeModal: () => void;
  categoryName: string;
  modalTitle?: string;
}) => {
  const { mutate: addAndroidTVHome, isPending: addAndroidTVHomeLoading } =
    useAddAndroidTVHome();

  const methods = useForm<IFormData>({
    defaultValues: {
      name: "",
      genre: null,
      selected: false,
    },
  });

  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = (data: IFormData, event?: React.BaseSyntheticEvent) => {
    const formData = new FormData();
    let payloadData = { ...data, genre: data.genre?.value };
    formData.append("categoryName", categoryName);
    formData.append("file", payloadData.file!);
    formData.append("thumbnail", payloadData.thumbnail!);
    delete payloadData.file;
    delete payloadData.thumbnail;
    formData.append("data", JSON.stringify(payloadData));

    addAndroidTVHome(formData, {
      onSuccess: (res) => {
        toast.success(res.result.message);
        handleClose();
      },
    });
  };

  const handleClose = () => {
    reset({
      name: "",
      genre: null,
      selected: false,
      file: undefined,
      thumbnail: undefined,
    });
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleClose}
      title={modalTitle || "Add Alarm Music"}
      className="w-144.75"
    >
      <FormProvider {...methods}>
        <TextInput
          label="Name"
          name="name"
          rules={{
            required: "Name is required",
          }}
        />
        <div className="flex items-center gap-16 mt-6.5">
          <Select
            label="Genre"
            options={GENRE_OPTIONS}
            name="genre"
            className="w-60"
          />
          <div className="mt-4">
            <Switch
              label="Enable on TV App"
              name="selected"
            />
          </div>
        </div>

        <div className="mt-11.25">
          <FileDropZone
            label="Upload Music"
            name="file"
            // onSelectOrDrop={(files) => setValue("file", files[0])}
            onClear={() => setValue("file", undefined)}
            accept=".mp3, .wav, .ogg"
            rules={{
              required: "Music is required",
            }}
          />
        </div>
        <div className="mt-11.25">
          <FileDropZone
            label="Thumbnail"
            name="thumbnail"
            // onSelectOrDrop={(files) => setValue("thumbnail", files[0])}
            onClear={() => setValue("thumbnail", undefined)}
            accept=".jpg, .jpeg, .png, .gif, .bmp, .webp"
            rules={{
              required: "Thumbnail is required",
            }}
          />
        </div>
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={handleClose}>
            Discard
          </Button>
          <Button
            className="w-25.5"
            variant="primary"
            loading={addAndroidTVHomeLoading}
            disabled={addAndroidTVHomeLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default AddAlarmMusicModal;
