import { ReactNode, useState, createContext, useContext, useMemo } from "react";
import Tab from "./Tab";

type ITabsProps = {
  children: ReactNode;
  tabs: { value: string; name: string }[];
  defaultValue?: string;
};

type TabsContextType = {
  activeTab: string;
  setActiveTab: (key: string) => void;
};

const TabsContext = createContext<TabsContextType | undefined>(undefined);

const Tabs = ({ children, tabs, defaultValue }: ITabsProps) => {
  const [activeTab, setActiveTab] = useState<string>(defaultValue || "");

  const value = useMemo(
    () => ({
      activeTab,
      setActiveTab,
    }),
    [activeTab]
  );

  return (
    <TabsContext.Provider value={value}>
      {tabs.length ? (
        <div className="flex items-center w-full">
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} name={tab.name} />
          ))}
        </div>
      ) : null}
      <div>{children}</div>
    </TabsContext.Provider>
  );
};

export default Tabs;

export const useTabsContext = (): TabsContextType => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabsContext must be used within a TabsProvider");
  }
  return context;
};
