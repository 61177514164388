import ReactDOM from "react-dom";
import classNames from "classnames";

type ISpinnerProps = {
  className?: string;
  fullScreenOverlay?: boolean;
};

const Spinner = ({ className, fullScreenOverlay }: ISpinnerProps) => {
  const Comp = (
    <div
      className={classNames(
        "h-12 w-12 rounded-full border border-[#F41095] border-b-0 border-b-transparent animate-spin",
        className
      )}
    />
  );

  if (fullScreenOverlay) {
    return ReactDOM.createPortal(
      <div
        className={classNames(
          "fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out" , className
        )}
      >
        {Comp}
      </div>,
      document.body
    );
  }

  return Comp;
};

export default Spinner;
