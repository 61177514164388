import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { queryClient } from "../App";
import { IUseDeleteOfferMutationResponse } from "../utils/types";

const useDeleteOffer = (): IUseDeleteOfferMutationResponse =>
  useMutation({
    mutationFn: async (data: any) => {
      const response = await AXIOS_RESERVATION.delete(`v1/coupons/${data.id}`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["offers"] });
    },
  });

export default useDeleteOffer;
