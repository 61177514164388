import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetCostCentersDataQueryResponse } from "../utils/types";

const useGetCostCentersDataQuery = (): IUseGetCostCentersDataQueryResponse =>
  useQuery({
    queryKey: ["sub-centers"],
    queryFn: async (data) => {
      const response = await AXIOS_RESERVATION.get(
        "v1/coupons/cost-centers",
        data
      );
      return response.data;
    },
  });

export default useGetCostCentersDataQuery;
