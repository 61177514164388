import {
  FieldError,
  RegisterOptions,
  useController,
  UseFormRegister,
} from "react-hook-form";
import { IconType } from "react-icons";
import { CheckboxTickSvg } from "../../../icons";
import classNames from "classnames";
import { getNestedError } from "../../../utils/helpers";
interface ICheckboxProps {
  label: string;
  name: string;
  register?: UseFormRegister<any>;
  error?: FieldError;
  validation?: RegisterOptions;
  Icon?: IconType;
  customClass?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  label,
  name,
  register,
  error,
  validation,
  Icon,
  customClass = "text-gray-400",
  rules,
}) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules });

  return (
    <div className="flex items-center space-x-2">
      <div className="relative flex items-center">
        <input
          id={name}
          type="checkbox"
          {...register?.(name, validation)}
          // onChange={(e) => setIsChecked(e.target.checked)}
          className="appearance-none h-5 w-5 border-2 border-gray-200 rounded-sm bg-white focus:outline-none focus:ring-2 focus:ring-pink-500"
          {...field}
        />
        {Icon ? (
          <Icon
            className={`w-4 h-4 text-white absolute left-0.5 top-0.5 pointer-events-none 
                ${field.value ? "block" : "hidden"}`}
          />
        ) : (
          <>
            <CheckboxTickSvg
              className={classNames(
                "w-4 h-4 text-pink-700 absolute left-0.5 top-0.5 pointer-events-none",
                field.value ? "block" : "hidden"
              )}
            />
          </>
        )}
      </div>
      <label className={`block text-sm font-bold ${customClass}`} id={name}>
        {label}
      </label>
      {error && <span className="text-red-500 text-sm">{error.message}</span>}
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
