import Switch from "../../../../components/common/Switch";
import Select from "../../../../components/common/Select";
import { TIME_OPTIONS } from "../../../../utils/constants";
import { useEffect, useState } from "react";
import DiscardSaveButtons from "../../../../components/tv-app/discard-save-buttons";
import {
  IChamomileTeaSubData,
  ISleepCategoryComponentProps,
} from "../../../../utils/types";
import { FormProvider, useForm } from "react-hook-form";

type IFormData = {
  showCT: boolean;
  data: { label: string; value: string } | undefined;
};

const ChamomileTea = ({
  subData,
  show,
  onSave,
  loading,
}: ISleepCategoryComponentProps<IChamomileTeaSubData[]>) => {
  const [InitialFormData, setInitialFormData] = useState<IFormData>({
    showCT: !!show,
    data: TIME_OPTIONS.find((x) => x.value === subData[0]?.value),
  });

  const methods = useForm<IFormData>({ defaultValues: InitialFormData });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    setInitialFormData({
      showCT: !!show,
      data: TIME_OPTIONS.find((x) => x.value === subData[0]?.value),
    });
  }, [subData, show]);

  return (
    <FormProvider {...methods}>
      <div className="w-full mt-4">
        <div className="">
          <Switch label="Chamomile Tea  (Hide/Show)" name="showCT" />
        </div>
        <h4 className="text-base leading-[39px] font-normal text-[#1E2F65] whitespace-nowrap">
          Chamomile Tea
        </h4>
        <div className="grid grid-cols-3 gap-2 relative">
          <Select
            label="Default Selected Time"
            options={TIME_OPTIONS}
            name="data"
          />
        </div>

        <DiscardSaveButtons
          onDiscard={() => {
            reset(InitialFormData);
          }}
          onSave={handleSubmit((submitData: IFormData) => {
            onSave({
              show: submitData.showCT,
              subData: [
                { ...subData[0], value: submitData.data?.value as string },
              ],
            });
          })}
          loading={loading}
          className="mt-10"
        />
      </div>
    </FormProvider>
  );
};

export default ChamomileTea;
