import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseUpdateOfferMutationResponse } from "../utils/types";
import { queryClient } from "../App";

const useUpdateOffer = ({
  id,
}: {
  id: string | undefined;
}): IUseUpdateOfferMutationResponse =>
  useMutation({
    mutationFn: async (data: any) => {
      const response = await AXIOS_RESERVATION.put(`v1/coupons/${id}`, data);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["offers"] });
    },
  });

export default useUpdateOffer;
