import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetOffersQueryResponse } from "../utils/types";

const useGetOffersQuery = (): IUseGetOffersQueryResponse =>
  useQuery({
    queryKey: ["offers"],
    queryFn: async (data) => {
      const response = await AXIOS_RESERVATION.get("v1/coupons", data);
      return response.data;
    },
  });

export default useGetOffersQuery;