import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const setAuthToken = (token: string) => {
  return Cookies.set("authToken", JSON.stringify(token));
};

export const getAuthToken = () => {
  const authToken = Cookies.get("authToken");
  if (authToken) {
    return JSON.parse(authToken);
  }
};

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return "0 B";

  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
};

export const objectToFormData = (
  data: any,
  formData = new FormData(),
  nestedKey = ""
) => {
  if (data === null || data === undefined) {
    return formData;
  }

  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      objectToFormData(item, formData, `${nestedKey}[${index}]`);
    });
  } else if (typeof data === "object") {
    if (data?.type?.includes("image")) {
      formData.append(nestedKey, data);
    } else {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          let formKey = nestedKey ? `${nestedKey}[${key}]` : key;

          objectToFormData(data[key], formData, formKey);
        }
      }
    }
  } else {
    formData.append(nestedKey, data);
  }

  return formData;
};

// getNestedError fuction will return the error from the nested field name joined with a dot
export const getNestedError = (errors: any, path: string) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], errors);
};

export const cleanObject = (payload: Record<string, any>) => {
  Object.keys(payload).forEach((key) => {
    const value = payload[key];

    if (value && typeof value === "object" && !Array.isArray(value)) {
      cleanObject(value);
    }

    if (!value && value !== 0) {
      delete payload[key];
    }
  });
};

export const successToast = (content: any) => {
  toast.success(content, { autoClose: 20000 });
};

export const errorToast = (content: any) => {
  if (Array.isArray(content)) {
    content.forEach((msg: string) => {
      toast.error(msg, { autoClose: 60000 });
    });
  } else {
    toast.error(content, { autoClose: 60000 });
  }
};
