import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseUpdateAndroidTVHomeMutationResponse } from "../utils/types";

const useUpdateAndroidTVHome = (): IUseUpdateAndroidTVHomeMutationResponse =>
  useMutation({
    mutationFn: async (data) => {
      const response = await AXIOS_RESERVATION.put(
        "update-android-tv-home",
        data
      );
      return response.data;
    },
  });

export default useUpdateAndroidTVHome;
