import classNames from "classnames";
import { FieldError, RegisterOptions, useController } from "react-hook-form";
import { IconType } from "react-icons";
import { getNestedError } from "../../../utils/helpers";

interface IDatePickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon?: IconType;
  iconClassName?: string;
  type?: string;
  label?: string;
  labelClassName?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const DatePicker: React.FC<IDatePickerProps> = ({
  Icon,
  iconClassName,
  label,
  labelClassName,
  rules,
  ...rest
}) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: rest.name || "", rules, disabled: rest.disabled });

  return (
    <div>
      {label && (
        <label
          className={classNames("block text-sm text-[#666672]", labelClassName)}
        >
          {label}
        </label>
      )}
      {Icon && (
        <span>
          <Icon
            className={classNames("absolute top-3 left-3", iconClassName)}
          />
        </span>
      )}
      <input
        type="date"
        className={classNames(
          "w-full  text-sm p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400 appearance-none datepicker-black-selection"
        )}
        {...field}
        {...rest}
        onChange={(e) => {
          field.onChange(e);
          rest?.onChange?.(e);
        }}
      />
      {errors && rest.name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, rest.name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default DatePicker;
