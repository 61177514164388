import SleepingEssentials from "./sleeping-essentials";
import WhiteNoise from "./white-noise";
import CalmingMusic from "./calming-music";
import ChamomileTea from "./chamomile-tea";
import TVOffTimer from "./tv-off-timer";
import Tabs from "../../../../components/common/Tabs";
import TabPanel from "../../../../components/common/Tabs/TabPanel";
import {
  ICalmingMusic,
  ICategoryComponentProps,
  IChamomileTea,
  ISleepingEssentials,
  ISleepSetting,
  ITVOffTimer,
  IWhiteNoise,
  SleepConciergeTypes,
} from "../../../../utils/types";

const SleepConcierge = ({ data, onSave, loading }: ICategoryComponentProps) => {
  // Handle save for all sleep setting
  const handleSave = ({
    newData,
    type,
  }: {
    newData: Partial<ISleepSetting> | FormData;
    type: string;
  }) => {
    let updatedData = [...data?.sleepSetting];
    const subDataIndex = updatedData?.findIndex((x) => x.type === type);
    // @ts-ignore
    updatedData[subDataIndex] = {
      ...updatedData[subDataIndex],
      ...newData,
    };

    onSave({
      categoryName: data?.categoryName,
      data: {
        ...data,
        sleepSetting: [...updatedData],
      },
    });
  };

  const tabs = [
    {
      value: SleepConciergeTypes.SLEEPING_ESSENTIALS,
      name: "Sleeping Essentials",
    },
    {
      value: SleepConciergeTypes.WHITE_NOISE_FOR_BETTER_SLEEP,
      name: "White Noise",
    },
    {
      value: SleepConciergeTypes.CALMING_MUSIC,
      name: "Calming Music",
    },
    {
      value: SleepConciergeTypes.CHAMOMILE_TEA,
      name: "Chamomile Tea",
    },
    {
      value: SleepConciergeTypes.TURN_OFF_LATER,
      name: "TV Off Timer",
    },
  ];

  return (
    <div>
      <h3 className="text-[22px] font-normal text-[#1E2F65]">
        Sleep Concierge
      </h3>
      <div className="mt-1">
        <Tabs
          tabs={tabs}
          defaultValue={SleepConciergeTypes.SLEEPING_ESSENTIALS}
        >
          <TabPanel value={SleepConciergeTypes.SLEEPING_ESSENTIALS}>
            <SleepingEssentials
              categoryName={data?.categoryName}
              {...(data?.sleepSetting?.find(
                (x) => x.type === SleepConciergeTypes.SLEEPING_ESSENTIALS
              ) as ISleepingEssentials)}
              onSave={(newData) =>
                handleSave({
                  newData,
                  type: SleepConciergeTypes.SLEEPING_ESSENTIALS,
                })
              }
              loading={loading}
            />
          </TabPanel>

          <TabPanel value={SleepConciergeTypes.WHITE_NOISE_FOR_BETTER_SLEEP}>
            <WhiteNoise
              categoryName={data?.categoryName}
              {...(data?.sleepSetting?.find(
                (x) =>
                  x.type === SleepConciergeTypes.WHITE_NOISE_FOR_BETTER_SLEEP
              ) as IWhiteNoise)}
              onSave={(newData) =>
                handleSave({
                  newData,
                  type: SleepConciergeTypes.WHITE_NOISE_FOR_BETTER_SLEEP,
                })
              }
              loading={loading}
            />
          </TabPanel>

          <TabPanel value={SleepConciergeTypes.CALMING_MUSIC}>
            <CalmingMusic
              categoryName={data?.categoryName}
              {...(data?.sleepSetting?.find(
                (x) => x.type === SleepConciergeTypes.CALMING_MUSIC
              ) as ICalmingMusic)}
              onSave={(newData) =>
                handleSave({ newData, type: SleepConciergeTypes.CALMING_MUSIC })
              }
              loading={loading}
            />
          </TabPanel>

          <TabPanel value={SleepConciergeTypes.CHAMOMILE_TEA}>
            <ChamomileTea
              categoryName={data?.categoryName}
              {...(data?.sleepSetting?.find(
                (x) => x.type === SleepConciergeTypes.CHAMOMILE_TEA
              ) as IChamomileTea)}
              onSave={(newData) =>
                handleSave({ newData, type: SleepConciergeTypes.CHAMOMILE_TEA })
              }
              loading={loading}
            />
          </TabPanel>

          <TabPanel value={SleepConciergeTypes.TURN_OFF_LATER}>
            <TVOffTimer
              categoryName={data?.categoryName}
              {...(data?.sleepSetting?.find(
                (x) => x.type === SleepConciergeTypes.TURN_OFF_LATER
              ) as ITVOffTimer)}
              onSave={(newData) =>
                handleSave({
                  newData,
                  type: SleepConciergeTypes.TURN_OFF_LATER,
                })
              }
              loading={loading}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default SleepConcierge;
