import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetEligibilityCriteriaQueryResponse } from "../utils/types";

const useGetEligibilityCriteriaQuery =
  (): IUseGetEligibilityCriteriaQueryResponse =>
    useQuery({
      queryKey: ["eligibility-criterias"],
      queryFn: async (data) => {
        const response = await AXIOS_RESERVATION.get(
          "v1/coupons/eligibility-criterias",
          data
        );
        return response.data;
      },
    });

export default useGetEligibilityCriteriaQuery;
