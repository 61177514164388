import axios from "axios";
import { getAuthToken } from "./helpers";

const url = window.location.pathname;
const parts = url.split("/");
const hotelcode = parts[1];

const AXIOS_RESERVATION = axios.create({
  baseURL: process.env.REACT_APP_RESERVATION_API,
  headers: {
    // hotelId: config.HOTELID,
    hotelcode,
  },
});

AXIOS_RESERVATION.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers["Authorization"] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AXIOS_RESERVATION.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

const AXIOS_HOSPITALITY = axios.create({
  baseURL: process.env.REACT_APP_HOSPITALITY_API,
  headers: {
    // hotelId: config.HOTELID,
    hotelcode,
  },
});

AXIOS_HOSPITALITY.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers["Authorization"] = authToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

AXIOS_HOSPITALITY.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export { AXIOS_RESERVATION, AXIOS_HOSPITALITY };
