import { FormProvider, useForm } from "react-hook-form";
import Modal from "../../common/Modal";
import TextInput from "../../common/TextInput";
import Select from "../../common/Select";
import Switch from "../../common/Switch";
import Button from "../../common/button";
import { QUANTITY_OPTIONS } from "../../../utils/constants";
import useAddAndroidTVHome from "../../../hooks/use-add-android-home";
import { toast } from "react-toastify";

interface IFormData {
  name: string;
  qty: { label: number; value: number } | null;
  selected: boolean;
}

const AddSleepingEssentialsModal = ({
  isOpen,
  closeModal,
  categoryName,
  type,
}: {
  isOpen: boolean;
  closeModal: () => void;
  categoryName: string;
  type: string;
}) => {
  const { mutate: addAndroidTVHome, isPending: addAndroidTVHomeLoading } =
    useAddAndroidTVHome();

  const method = useForm<IFormData>({
    defaultValues: {
      name: "",
      qty: QUANTITY_OPTIONS[0],
      selected: false,
    },
  });

  const { handleSubmit, reset } = method;

  const onSubmit = (data: IFormData) => {
    const payload = { ...data, qty: data.qty?.value };
    addAndroidTVHome(
      { categoryName, type, data: payload },
      {
        onSuccess: (res) => {
          toast.success(res.result.message);
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    reset();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleClose}
      title="Add Sleeping Essential"
      className="w-144.75"
    >
      <FormProvider {...method}>
        <TextInput
          label="Name"
          name="name"
          rules={{
            required: "Name is required",
          }}
        />
        <div className="flex items-center gap-16 mt-6.5">
          {/* @ts-ignore */}
          <Select
            name="qty"
            label="Qty"
            options={QUANTITY_OPTIONS}
            className="w-60"
          />
          <div className="mt-4">
            <Switch
              name="selected"
              label="Enable on TV App"
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={handleClose}>
            Discard
          </Button>
          <Button
            className="w-25.5"
            variant="primary"
            loading={addAndroidTVHomeLoading}
            disabled={addAndroidTVHomeLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default AddSleepingEssentialsModal;
