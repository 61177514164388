import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import IntroPage from "./pages/intro-page";
import Routers from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const queryClient = new QueryClient();

function App() {
  return (
    <Fragment>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<IntroPage />} />
            <Route path="/:hotelcode/*" element={<Routers />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Fragment>
  );
}
export default App;
