import { HTMLInputTypeAttribute, useRef, useState } from "react";
import classNames from "classnames";
import { FieldError, RegisterOptions, useController } from "react-hook-form";
import { getNestedError } from "../../utils/helpers";

interface IEditTableRowNameProps {
  name: string;
  onChange?: (name: string) => void;
  inputType?: HTMLInputTypeAttribute;
  suffix?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const EditTableRowName = ({
  name,
  onChange,
  inputType,
  suffix,
  rules,
}: IEditTableRowNameProps) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules });

  const [shoeEdit, setShowEdit] = useState(false);
  // const [newName, setNewName] = useState<string>();

  const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => setNewName(name), [name]);
  const { onBlur, ref, ...restFields } = field;

  return (
    <>
      <input
        ref={inputRef}
        type={inputType}
        onBlur={() => {
          setShowEdit(false);
          inputRef.current?.blur();
        }}
        {...restFields}
        className={classNames(
          "p-2 bg-black/10 w-full",
          shoeEdit ? "block" : "hidden"
        )}
      />
      {!shoeEdit && (
        <div
          className="text-[#666672] min-w-20 h-4 cursor-text"
          onClick={() => {
            setShowEdit(true);
            const timeout = setTimeout(() => {
              inputRef.current?.focus();
              clearTimeout(timeout);
            }, 0);
          }}
        >
          {field.value}
          {suffix}
        </div>
      )}
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </>
  );
};

export default EditTableRowName;
