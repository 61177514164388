import { Suspense } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Loader from "../layout/loader";
import Login from "../pages/auth/login";
import LayoutRoutes from "./layout-routes";
import PrivateRouteCheck from "./private-route-check";
const Routers = () => {
  const params = useParams();
  const jwtToken = localStorage.getItem("token");

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={"/"}
          // element={<PrivateRoute />}
        >
          {jwtToken ? (
            <>
              <Route
                path={`${process.env.PUBLIC_URL}`}
                element={
                  <Navigate
                    to={`${process.env.PUBLIC_URL}/${params.hotelcode}/tv-app/room/lights`}
                  />
                }
              />
            </>
          ) : (
            <Route
              path={`${process.env.PUBLIC_URL}`}
              element={
                <Navigate
                  to={`${process.env.PUBLIC_URL}/${params.hotelcode}/login`}
                />
              }
            />
          )}
          <Route
            path={`/*`}
            element={
              <PrivateRouteCheck>
                <LayoutRoutes />
              </PrivateRouteCheck>
            }
          />
        </Route>
        {/* <Route
                  path={`${process.env.PUBLIC_URL}/callback`}
                  render={() => <Callback />}
                /> */}
        <Route
          // exact
          path={`${process.env.PUBLIC_URL}/login`}
          element={<Login />}
        />
        {/* {authRoutes.map(({ path, Component }, i) => (
                  <Route path={path} element={Component} key={i} />
                ))} */}
      </Routes>
    </Suspense>
  );
};
export default Routers;
