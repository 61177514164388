import { SidebarToggleSvg } from "../../icons";

type IHeaderTypes = {
  onToggleSidebar: () => void;
};

const Header = ({ onToggleSidebar }: IHeaderTypes) => {
  return (
    <div className="flex items-center justify-between px-6 py-[30px] h-[72px] bg-white">
      <div className="cursor-pointer" onClick={onToggleSidebar}>
        <SidebarToggleSvg />
      </div>
    </div>
  );
};

export default Header;
