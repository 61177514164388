import classNames from "classnames";
import Button from "../common/button";

interface IDiscardSaveButtonsProps {
  onDiscard: () => void;
  onSave: () => void;
  loading?: boolean;
  className?: string;
}

const DiscardSaveButtons = ({
  onDiscard,
  onSave,
  loading,
  className,
}: IDiscardSaveButtonsProps) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-end gap-[18px]",
        className
      )}
    >
      <Button className="w-25.5" onClick={onDiscard}>
        Discard
      </Button>
      <Button
        className="w-25.5"
        variant="primary"
        loading={loading}
        disabled={loading}
        onClick={onSave}
      >
        Save
      </Button>
    </div>
  );
};

export default DiscardSaveButtons;
