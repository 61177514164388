import { ReactNode, useEffect, useRef, useState } from "react";
import { ActionMeta, Props } from "react-select";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import Select from "../Select";

interface ISelectProps extends Omit<Props, "onChange"> {
  label?: string | ReactNode;
  onChange?: (newValue: any, actionMeta: ActionMeta<unknown>) => void;
  wrapperComponentStyle?: string;
  checkboxOptions?: boolean;
  register?: UseFormRegister<any>;
  error?: FieldError;
  validation?: RegisterOptions;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  dynamicOptionsReset?: boolean;
  handleDynamicOptionsReset?: () => void;
}

export type ISelectOptionType = { label: string; value: string | number };

const DynamicMultiSelect = ({
  dynamicOptionsReset,
  handleDynamicOptionsReset,
  ...rest
}: ISelectProps) => {
  const [multiSelectOptions, setmultiSelectOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [newmultiSelect, setnewmultiSelect] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const currentMultiSelectValueValue = useRef<string>("");

  useEffect(() => {
    setmultiSelectOptions([]);
    setnewmultiSelect(null);
    currentMultiSelectValueValue.current = "";
    handleDynamicOptionsReset?.();
  }, [dynamicOptionsReset]);

  return (
    <Select
      {...rest}
      options={
        newmultiSelect
          ? [...multiSelectOptions, newmultiSelect]
          : multiSelectOptions
      }
      inputValue={newmultiSelect?.value}
      onInputChange={(newvalue) => {
        const value = newvalue.split(/\s+/).join("");
        setnewmultiSelect({
          label: value,
          value: value,
        });
        if (!newvalue) {
          setnewmultiSelect(null);
        }
      }}
      onKeyDown={(e) => {
        if (
          e.key === "Enter" &&
          newmultiSelect &&
          currentMultiSelectValueValue.current !== newmultiSelect.value
        ) {
          currentMultiSelectValueValue.current = newmultiSelect.value
            .split(/\s+/)
            .join("");
          setmultiSelectOptions((pre) => [...pre, newmultiSelect]);
          setnewmultiSelect(null);
        }
      }}
      onBlur={() => {
        setnewmultiSelect(null);
      }}
      onChange={() => {
        if (
          newmultiSelect &&
          currentMultiSelectValueValue.current !== newmultiSelect.value
        ) {
          setmultiSelectOptions((pre) => [...pre, newmultiSelect]);
          setnewmultiSelect(null);
        }
      }}
    />
  );
};

export default DynamicMultiSelect;
