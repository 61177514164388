import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Switch from "../../../components/common/Switch";
import Button from "../../../components/common/button";
import AddAlarmMusicModal from "../../../components/tv-app/alarm/add-alarm-music-modal";
import DeleteAndroidHome from "../../../components/tv-app/delete-android-home";
import EditTableRowName from "../../../components/tv-app/edit-name";
import DiscardSaveButtons from "../../../components/tv-app/discard-save-buttons";
import {
  ICategoryComponentProps,
  ISounds,
  ITableColumn,
} from "../../../utils/types";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

interface IFormData {
  showMusic: boolean;
  soundsData: ISounds[];
}

const Music = ({ data, loading, onSave }: ICategoryComponentProps) => {
  console.log(data.sounds , 'afgahsdkh')
  const getInitialFormData = () => ({
    showMusic: !!data?.musicSettings?.show,
    soundsData: data?.sounds,
  });

  const [InitialFormData, setInitialFormData] = useState<IFormData>(
    getInitialFormData()
  );

  const methods = useForm<IFormData>({ defaultValues: InitialFormData });

  const { reset, handleSubmit } = methods;

  const {
    fields: musicFields,
    append: appendMusicField,
    remove: removeMusicField,
  } = useFieldArray({
    name: "soundsData",
    control: methods.control,
    keyName: "customId",
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    // setSoundsData(JSON.parse(JSON.stringify(data?.sounds)));
    if (musicFields.length < data.sounds.length) {
      const addedElement = data.sounds[data.sounds.length - 1];
      appendMusicField({ ...addedElement });
      setInitialFormData((pre) => ({
        ...pre,
        data: [...pre.soundsData, { ...addedElement }],
      }));
    }
  }, [data.sounds]);

  const getTableDataIndex = (id : string) => {
    return musicFields.findIndex((item) => item.id === id);
  }

  const tableColumns: ITableColumn<ISounds>[] = [
    {
      name: "Name",
      cell: (Row) => <EditTableRowName name={`soundsData.${getTableDataIndex(Row.id)}.name`} />,
      sortable: true,
      width: "40%",
    },
    {
      name: "",
      cell: (Row) => <span></span>,
      width: "20%",
    },
    {
      name: "View",
      cell: (Row) => <Switch name={`soundsData.${getTableDataIndex(Row.id)}.selected`} />,
      sortable: true,
      center: true,
      width: "20%",
    },
    {
      name: "Status",
      cell: (Row) => (
        <DeleteAndroidHome
          categoryName={data?.categoryName}
          deleteId={Row.id}
          removeField={() => {
            setInitialFormData((pre) => ({
              ...pre,
              data: pre.soundsData.filter((item) => item.id !== Row.id),
            }));
            removeMusicField(getTableDataIndex(Row.id));
          }}
        />
      ),
      sortable: true,
      center: true,
      width: "20%",
    },
  ];

  return (
    <FormProvider {...methods}>
      <div>
        <h3 className="text-[22px] font-normal text-[#1E2F65]">Music</h3>
        <div className="">
          <Switch
            name="showMusic"
            label="Music (Hide/Show)"
          />
        </div>
        <div className="flex items-center justify-between w-full mt-4">
          <h4 className="text-base leading-[39px] font-normal text-[#1E2F65]">
            Music List
          </h4>
          <Button variant="primary" className="w-21" onClick={openModal}>
            Add
          </Button>
        </div>
        {/* <div className="h-full max-h-[calc(100vh-440px)] overflow-auto mt-[17px]"> */}
        <DataTable
          className="border-t mt-6"
          data={musicFields}
          columns={tableColumns}
          striped={true}
          keyField="customId"
          persistTableHead
          pagination={musicFields.length > 10}
          customStyles={{
            headRow: { style: { paddingLeft: "36px" } },
            rows: { style: { paddingLeft: "36px" } },
          }}
        />
        {/* </div> */}

        <DiscardSaveButtons
          onDiscard={() => {
            reset(getInitialFormData)
          }}
          onSave={handleSubmit((submitData : IFormData) =>
            onSave({
              categoryName: data?.categoryName,
              data: {
                ...data,
                musicSettings: {
                  ...(data?.musicSettings || {}),
                  show: submitData.showMusic,
                  subData: submitData.soundsData,
                },
                sounds: submitData.soundsData,
              },
            }))
          }
          loading={loading}
          className="mt-10"
        />

        <AddAlarmMusicModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          categoryName={data.categoryName}
          modalTitle="Add New Music"
        />
      </div>
    </FormProvider>
  );
};

export default Music;
