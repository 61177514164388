export enum OfferType {
  CURRENT_OFFERS = "CURRENT_OFFERS",
  CREATE_OFFER = "CREATE_OFFER",
}

export type ISubCenter = {
  _id: string;
  name: string;
  subCenters: {
    name: string;
    _id: string;
  }[];
};

export type IBusinessReason = {
  _id: string;
  name: string;
};

export type IEligibilityCriteria = {
  _id: string;
  name: string;
  createdAt: string;
  key: string;
  options: any[];
  updatedAt: string;
  valueType: string;
  viewRank: number;
  __v: number;
};

export type ICouponType = {
  _id: string;
  name: string;
  valueType: string;
  key: string;
};
