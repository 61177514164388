import { FieldError, RegisterOptions, useController } from "react-hook-form";
import { IconType } from "react-icons";
import classNames from "classnames";
import React from "react";
import { CloseSvg } from "../../../icons";
import { getNestedError } from "../../../utils/helpers";

interface IFileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  Icon?: IconType;
  error?: FieldError;
  labelClassName?: string;
  iconClassName?: string;
  inputClassName?: string;
  ref?: React.Ref<HTMLInputElement>;
  onClear?: () => void;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const FileInput: React.FC<IFileInputProps> = ({
  label,
  name,
  error,
  Icon,
  labelClassName,
  iconClassName,
  inputClassName,
  onClear,
  rules,
  ...rest
}) => {
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules, disabled: rest.disabled });
  const { ref, value, ...otherFieldValue } = field;
  return (
    <div>
      {label && (
        <label
          className={classNames("block text-sm text-gray-700", labelClassName)}
        >
          {label}
        </label>
      )}
      <div className={classNames("relative flex", label ? "mt-1" : "")}>
        {Icon && (
          <span className={classNames("absolute top-3 left-3", iconClassName)}>
            <Icon />
          </span>
        )}
        <input
          type="file"
          ref={inputFileRef}
          className={classNames(
            "w-full p-2 border text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-black font-sans",
            Icon ? "pl-10" : "",
            inputClassName
          )}
          {...otherFieldValue}
          {...rest}
        />

        {inputFileRef.current?.files &&
          inputFileRef.current.files.length > 0 && (
            <CloseSvg
              onClick={() => {
                onClear?.();
                inputFileRef.current!.value = "";
              }}
              className="text-[#1D1B20] cursor-pointer"
            />
          )}
      </div>
      {error && <span className="text-red-500 text-sm">{error.message}</span>}
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default FileInput;
