import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetBusinessReasonsQueryResponse } from "../utils/types";

const useGetBusinessReasonsDataQuery =
  (): IUseGetBusinessReasonsQueryResponse =>
    useQuery({
      queryKey: ["business-reasons"],
      queryFn: async (data) => {
        const response = await AXIOS_RESERVATION.get(
          "v1/coupons/business-reasons",
          data
        );
        return response.data;
      },
    });

export default useGetBusinessReasonsDataQuery;
