import {
  FieldError,
  RegisterOptions,
  useController,
  UseFormRegister,
} from "react-hook-form";
import { IconType } from "react-icons";
import classNames from "classnames";
import { getNestedError } from "../../../utils/helpers";

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  register?: UseFormRegister<any>;
  Icon?: IconType;
  error?: FieldError;
  validation?: RegisterOptions;
  togglePassword?: () => void;
  showPassword?: boolean;
  labelClasses?: string;
  iconClasses?: string;
  inputClasses?: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const TextInput: React.FC<ITextInputProps> = ({
  label,
  type = "text",
  name,
  register,
  placeholder,
  Icon,
  error,
  validation,
  togglePassword,
  showPassword,
  labelClasses,
  iconClasses,
  inputClasses,
  rules,
  ...rest
}) => {
  // Using useController to get the field and form state errors
  const {
    field,
    formState: { errors },
  } = useController({ name: name || "", rules });
  return (
    <div>
      {label && (
        <label
          className={classNames("block text-sm text-[#666672]", labelClasses)}
        >
          {label}
        </label>
      )}
      <div className="relative mt-1 flex">
        {Icon && (
          <span>
            <Icon
              className={classNames("absolute top-3 left-3 ", iconClasses)}
            />
          </span>
        )}
        <input
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          placeholder={placeholder}
          {...register?.(name!, validation)}
          className={classNames(
            "w-full text-sm p-2 border rounded-md focus:outline-none placeholder-custom_placeholder focus:ring-1 focus:ring-gray-400 font-sans",
            Icon ? "pl-10" : "",
            inputClasses
          )}
          {...field}
          {...rest}
          onChange={(e) => {
            field.onChange(e);
            rest?.onChange?.(e);
          }}
        />
        {type === "password" && togglePassword && (
          <button
            type="button"
            onClick={togglePassword}
            className="absolute inset-y-0 right-0 pr-3 text-sm text-blue-500 hover:underline"
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        )}
      </div>
      {error && <span className="text-red-500 text-sm">{error.message}</span>}
      {errors && name && (
        <span className="text-red-500 text-sm">
          {/* This line casts the error to FieldError and retrieves its message */}
          {(getNestedError(errors, name) as FieldError)?.message}
        </span>
      )}
    </div>
  );
};

export default TextInput;
