import { IDefaultOnOff } from "./types";

export const TIME_OPTIONS = [
  { label: "12 : 00 AM", value: "12 : 00 AM" },
  { label: "01 : 00 AM", value: "01 : 00 AM" },
  { label: "02 : 00 AM", value: "02 : 00 AM" },
  { label: "03 : 00 AM", value: "03 : 00 AM" },
  { label: "04 : 00 AM", value: "04 : 00 AM" },
  { label: "05 : 00 AM", value: "05 : 00 AM" },
  { label: "06 : 00 AM", value: "06 : 00 AM" },
  { label: "07 : 00 AM", value: "07 : 00 AM" },
  { label: "08 : 00 AM", value: "08 : 00 AM" },
  { label: "09 : 00 AM", value: "09 : 00 AM" },
  { label: "10 : 00 AM", value: "10 : 00 AM" },
  { label: "11 : 00 AM", value: "11 : 00 AM" },
  { label: "12 : 00 PM", value: "12 : 00 PM" },
  { label: "01 : 00 PM", value: "01 : 00 PM" },
  { label: "02 : 00 PM", value: "02 : 00 PM" },
  { label: "03 : 00 PM", value: "03 : 00 PM" },
  { label: "04 : 00 PM", value: "04 : 00 PM" },
  { label: "05 : 00 PM", value: "05 : 00 PM" },
  { label: "06 : 00 PM", value: "06 : 00 PM" },
  { label: "07 : 00 PM", value: "07 : 00 PM" },
  { label: "08 : 00 PM", value: "08 : 00 PM" },
  { label: "09 : 00 PM", value: "09 : 00 PM" },
  { label: "10 : 00 PM", value: "10 : 00 PM" },
  { label: "11 : 00 PM", value: "11 : 00 PM" },
];

export const FAN_SPEED_OPTIONS = [
  { label: "Level 1 (15)", value: "15" },
  { label: "Level 2 (30)", value: "30" },
  { label: "Level 3 (45)", value: "45" },
];

// Fahrenheit
export const TEMPERATURE_OPTIONS = [
  { label: 61, value: 61 },
  { label: 62, value: 62 },
  { label: 63, value: 63 },
  { label: 64, value: 64 },
  { label: 65, value: 65 },
  { label: 66, value: 66 },
  { label: 67, value: 67 },
  { label: 68, value: 68 },
  { label: 69, value: 69 },
  { label: 70, value: 70 },
  { label: 71, value: 71 },
  { label: 72, value: 72 },
  { label: 73, value: 73 },
  { label: 74, value: 74 },
  { label: 75, value: 75 },
  { label: 76, value: 76 },
  { label: 77, value: 77 },
  { label: 78, value: 78 },
  { label: 79, value: 79 },
  { label: 80, value: 80 },
  { label: 81, value: 81 },
  { label: 82, value: 82 },
  { label: 83, value: 83 },
  { label: 84, value: 84 },
  { label: 85, value: 85 },
  { label: 86, value: 86 },
].map((x) => ({ ...x, cValue: (x.value - 32) * (5 / 9) }));

export const TEMPERATURE_UNIT_OPTIONS = [
  { label: "Fahrenheit (°F)", value: "F" },
  { label: "Celsius (°C)", value: "C" },
];

export const ON_OFF_OPTIONS : {label : string; value: IDefaultOnOff}[] = [
  { label: "ON", value: "on" },
  { label: "OFF", value: "off" },
];

export const VOLUME_OPTIONS = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];

export const QUANTITY_OPTIONS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

export const GENRE_OPTIONS = [
  { value: "Country", label: "Country" },
  { value: "Instrumental", label: "Instrumental" },
  { value: "Jazz", label: "Jazz" },
  { value: "Zen", label: "Zen" },
  { value: "Other", label: "Other" },
];