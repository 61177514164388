import { useState } from "react";
import useDeleteAndroidTVHome from "../../hooks/use-delete-android-home";
import { TrashSvg } from "../../icons";
import Button from "../common/button";
import Modal from "../common/Modal";
import { toast } from "react-toastify";
// import { UseFieldArrayRemove } from "react-hook-form";

interface IDeleteAndroidHomeProps {
  categoryName: string;
  type?: string;
  deleteId: string;
  removeField?: () => void;
}

const DeleteAndroidHome = ({
  categoryName,
  type,
  deleteId,
  removeField,
}: IDeleteAndroidHomeProps) => {
  const { mutate: deleteAndroidTVHome, isPending: deleteAndroidTVHomeLoading } =
    useDeleteAndroidTVHome();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDelete = () => {
    deleteAndroidTVHome(
      { categoryName, type, deleteId },
      {
        onSuccess: (res) => {
          removeField?.();
          toast.success(res?.result?.message);
          closeModal();
        },
      }
    );
  };

  return (
    <>
      <div
        onClick={openModal}
        className="h-6 w-6 rounded-[3.63px] bg-[#E5272733] flex items-center justify-center cursor-pointer"
      >
        <TrashSvg color="#E52727" />
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title="Are you sure to delete this?"
        className="w-144.75"
      >
        <div className="flex items-center justify-end gap-[18px] mt-9">
          <Button className="w-25.5" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="w-25.5"
            variant="primary"
            loading={deleteAndroidTVHomeLoading}
            disabled={deleteAndroidTVHomeLoading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAndroidHome;
