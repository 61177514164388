import { useTabsContext } from ".";
import classNames from "classnames";

type TabProps = {
  value: string;
  name: string;
};

const Tab = ({ value, name }: TabProps) => {
  const { activeTab, setActiveTab } = useTabsContext();

  return (
    <div
      className={classNames(
        "w-full text-sm leading-[21px] font-normal text-center py-[11px] cursor-pointer",
        activeTab === value
          ? "text-[#F41095] border-b border-[#F41095]"
          : "text-[#666672] border-b border-[#D9D9D9]"
      )}
      onClick={() => setActiveTab(value)}
    >
      {name}
    </div>
  );
};

export default Tab;
