import React, { PropsWithChildren, ReactNode } from "react";
import ReactEasySort, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import classNames from "classnames";

interface ISortableListProps {
  children: ReactNode[];
  sortableArray: any[];
  onSort: (newArray: []) => void;
  direction?: "vertical" | "horizontal";
  itemClassName?: string;
}

const SortableList = ({
  children,
  direction = "vertical",
  sortableArray = [],
  onSort,
  itemClassName,
}: PropsWithChildren<ISortableListProps>) => {
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    onSort(arrayMoveImmutable(sortableArray, oldIndex, newIndex) as []);
  };

  return (
    <ReactEasySort
      onSortEnd={onSortEnd}
      className={classNames(
        "flex flex-shrink-0 gap-4 select-none w-full",
        direction === "horizontal" ? "flex-row" : "flex-col"
      )}
      draggedItemClassName="shadow-lg opacity-50 cursor-grabbing"
    >
      {children?.map((child, i) => (
        <SortableItem key={i + 1}>
          <div
            className={classNames(
              "flex items-center cursor-grab",
              itemClassName
            )}
          >
            {child}
          </div>
        </SortableItem>
      ))}
    </ReactEasySort>
  );
};

export default SortableList;

export const CustomKnob = React.forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => {
    return <div ref={ref}>{children}</div>;
  }
);
