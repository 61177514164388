import React from "react";

const IntroPage: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen min-w-screen bg-gray-100 ">
      <div className="bg-white w-[560px] min-h-[65vh] text-center py-16">
        <div className="flex justify-center mb-6">
          <div className="w-50 h-50 pt-4">
            <img src="/images/icons/hotelCartoon.svg" alt="" />
          </div>
        </div>
        <h1 className="text-2xl  mb-7 mt-20 ">This is the Shashi Booking Engine page.</h1>
        <p className="text-gray-600 text-xl  mb-4 mt-4">
          Please go to the hotel-specific booking page to <br />
          continue with your reservation process.
        </p>
      </div>
    </div>
  );
}

export default IntroPage;
