import { useMutation } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
// import { queryClient } from "../App";
import { IUseDeleteAndroidTVHomeMutationResponse } from "../utils/types";

const useDeleteAndroidTVHome = (): IUseDeleteAndroidTVHomeMutationResponse =>
  useMutation({
    mutationFn: async (data) => {
      const response = await AXIOS_RESERVATION.delete(
        "android-tv-home/delete-data",
        { params: data }
      );
      return response.data;
    },
    onSuccess: () => {
      // queryClient.refetchQueries({ queryKey: ["categories"] });
    },
  });

export default useDeleteAndroidTVHome;
