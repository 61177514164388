import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetCategoriesQueryResponse } from "../utils/types";

const useGetCategoriesQuery = (): IUseGetCategoriesQueryResponse =>
  useQuery({
    queryKey: ["categories"],
    queryFn: async (data) => {
      const response = await AXIOS_RESERVATION.get("getAndroidTVHome", data);
      return response.data;
    },
  });

export default useGetCategoriesQuery;
