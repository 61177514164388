import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { CloseSvg } from "../../../icons";

interface IModalProps {
  isOpen: boolean;
  closeModal: () => void;
  title?: string;
  children: React.ReactNode;
  className?: string;
  headerClassName?: string;
  modelContentClassName?: string;
}

const Modal = ({
  isOpen,
  closeModal,
  title,
  children,
  className,
  headerClassName,
  modelContentClassName,
}: IModalProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(isOpen);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else if (modalRef.current) {
      const modalElement = modalRef.current;

      const handleTransitionEnd = () => {
        if (!isOpen) {
          setIsVisible(false);
        }
        modalElement.removeEventListener("transitionend", handleTransitionEnd);
      };

      modalElement.addEventListener("transitionend", handleTransitionEnd);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [closeModal]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div
      className={classNames(
        "fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out",
        isOpen ? "opacity-100" : "opacity-0"
      )}
    >
      <div
        ref={modalRef}
        className={classNames(
          "bg-white p-[30px] pt-5 transform transition-all duration-300 ease-in-out",
          isOpen ? "scale-100 opacity-100" : "scale-95 opacity-0",
          className
        )}
      >
        <div
          className={classNames(
            "flex justify-between items-center",
            headerClassName
          )}
        >
          <h2 className="text-xl leading-6 font-medium">{title}</h2>
          <button
            onClick={closeModal}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <CloseSvg />
          </button>
        </div>
        <div className={classNames("mt-4", modelContentClassName)}>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
