import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { tvAppRoutes } from "../../routes/routes";
import Accordian, {
  AccordianContent,
  AccordianHeader,
  AccordianItem,
} from "../../components/common/Accordian";
import { DragSvg } from "../../icons";
import getAndroidTVHome from "../../hooks/use-get-android-home";
import Spinner from "../../components/common/Spinner";
import useUpdateAndroidTVHome from "../../hooks/use-update-android-home";
import { toast } from "react-toastify";
import SortableList, { CustomKnob } from "../../components/common/SortableList";
import { SortableKnob } from "react-easy-sort";
import Card from "../../components/common/Card";
import { ISubCategory } from "../../utils/types";
import { queryClient } from "../../App";

interface ICategory {
  id: string;
  title: string;
  subMenus: (ISubCategory & {
    key: string;
    title: string;
    path: string;
  })[];
  active: boolean;
}

const TVApp = () => {
  const {
    data: androidTVHomeData,
    isLoading,
    isFetching,
    error,
  } = getAndroidTVHome();
  const { mutate: updateAndroidTVHome, isPending: updateAndroidTVHomeLoading } =
    useUpdateAndroidTVHome();
  const params = useParams();
  const location = useLocation();
  const [categories, setCategories] = useState<Array<ICategory>>([]);

  useEffect(() => {
    if (androidTVHomeData?.result?.data?.subCategories?.length) {
      let temp: typeof categories = [];
      for (
        let i = 0;
        i < androidTVHomeData.result.data.subCategories.length;
        i++
      ) {
        const cat = androidTVHomeData.result.data.subCategories[i];
        const index = temp.findIndex((x) => x.title === cat.parentKey);
        if (index !== -1) {
          temp[index].subMenus.push({
            ...cat,
            title: cat.displayName,
            key: cat.categoryName,
            path: `/${
              params.hotelcode
            }/tv-app/${cat.parentKey?.toLowerCase()}/${cat.categoryName?.toLowerCase()}`,
          });
        } else {
          const tempSubCat = {
            id: cat.parentKey,
            title: cat.parentKey,
            active: location.pathname.includes(
              `/tv-app/${cat.parentKey?.toLowerCase()}`
            ),
            subMenus: [
              {
                ...cat,
                title: cat.displayName,
                key: cat.categoryName,
                path: `/${
                  params.hotelcode
                }/tv-app/${cat.parentKey?.toLowerCase()}/${cat.categoryName?.toLowerCase()}`,
              },
            ],
          };
          temp.push(tempSubCat);
        }
      }
      setCategories(temp);
    }
  }, [androidTVHomeData, isFetching]);

  const onSave = (
    data: { categoryName: string; data: ISubCategory } | FormData
  ) => {
    updateAndroidTVHome(data, {
      onSuccess: async (res) => {
        toast.success(res.result.message);
        await queryClient.refetchQueries({ queryKey: ["categories"] });
      },
      onError: (err) => {
        toast.error(err?.response!?.data?.result?.message);
      },
    });
  };

  return (
    <div className="p-6 min-h-full">
      <h2 className="text-xl leading-[26.4px] font-normal -tracking-[0.5px]">
        TV App
      </h2>
      {isLoading || isFetching ? <Spinner fullScreenOverlay /> : null}
      {categories?.length ? (
        <div className="grid grid-cols-[278px_1fr] gap-6 w-full h-full mt-6">
          <Card className="p-[17px] pt-[25px] h-max">
            <h3 className="text-lg leading-[26.4px] font-normal text-[#1E2F65]">
              Category List
            </h3>
            <div className="mt-[25px] flex flex-col gap-2.5">
              <Accordian defaultActiveId={categories.find((x) => x.active)?.id}>
                <SortableList
                  key={location.pathname}
                  sortableArray={categories}
                  onSort={(newArray) => {
                    setCategories(newArray);
                  }}
                >
                  {categories.map((category) => (
                    <AccordianItem id={category.id} key={category.id}>
                      {/* @ts-ignore */}
                      {({ ref }) => (
                        <>
                          <AccordianHeader
                            id={category.id}
                            icon={
                              <SortableKnob>
                                <CustomKnob ref={ref}>
                                  <DragSvg
                                    height={24}
                                    width={24}
                                    className="cursor-grab"
                                  />
                                </CustomKnob>
                              </SortableKnob>
                            }
                          >
                            {category.title}
                          </AccordianHeader>
                          <AccordianContent
                            id={category.id}
                            className="select-none"
                          >
                            <div className="flex flex-col pl-5">
                              {category.subMenus?.map((subMenu) => (
                                <Link
                                  key={subMenu.path}
                                  to={subMenu.path}
                                  className={classNames(
                                    "text-sm leading-[39px] font-normal",
                                    `${
                                      location.pathname.includes(
                                        subMenu.path
                                      ) || location.pathname === subMenu.path
                                        ? "text-[#F41095]"
                                        : "text-[#898989]"
                                    }`
                                  )}
                                >
                                  <div className="flex items-center gap-2.5">
                                    <span className="h-px w-1.5 bg-[#898989]" />
                                    <span
                                      style={{
                                        color:
                                          location.pathname.includes(
                                            subMenu.path
                                          ) ||
                                          location.pathname === subMenu.path
                                            ? "#F41095"
                                            : "",
                                      }}
                                    >
                                      {subMenu.title}
                                    </span>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </AccordianContent>
                        </>
                      )}
                    </AccordianItem>
                  ))}
                </SortableList>
              </Accordian>
            </div>
          </Card>
          <Card className="h-max">
            <Routes>
              {tvAppRoutes.map(({ path, Component }, i) => {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Component
                        data={
                          categories
                            .find((x) => path.includes(x.title.toLowerCase()))
                            ?.subMenus?.find((x) =>
                              path.includes(x.key.toLowerCase())
                            )!
                        }
                        onSave={onSave}
                        loading={updateAndroidTVHomeLoading}
                      />
                    }
                  />
                );
              })}
              <Route
                path={"*"}
                element={
                  <div className="h-96 flex items-center justify-center">
                    <h1 className="text-3xl">Comming Soon</h1>
                  </div>
                }
              />
            </Routes>
          </Card>
        </div>
      ) : error ? (
        <Card className="mt-4">
          <h2 className="text-xl text-center center">
            Data not found or something went wrong <br />
            <span className="text-secondary">({error?.message})</span>
          </h2>
        </Card>
      ) : null}
    </div>
  );
};

export default TVApp;
