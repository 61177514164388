import { OfferType } from "../../../utils/types/offers";
import Tabs from "../../../components/common/Tabs";
import TabPanel from "../../../components/common/Tabs/TabPanel";
import CurrentOffer from "./offers/current-offers";
import CreateOffer from "./offers/create-offers";

const Offers: React.FC = () => {
  const tabs = [
    {
      value: OfferType.CURRENT_OFFERS,
      name: "Current Offers",
    },
    {
      value: OfferType.CREATE_OFFER,
      name: "Create Offers",
    },
  ];
  return (
    <div>
      <h3 className="text-[22px] font-normal text-[#1E2F65]">Offers</h3>

      <div className="mt-1">
        <Tabs tabs={tabs} defaultValue={OfferType.CURRENT_OFFERS}>
          <TabPanel value={OfferType.CURRENT_OFFERS}>
            <CurrentOffer />
          </TabPanel>
          <TabPanel value={OfferType.CREATE_OFFER}>
            <CreateOffer isEditOffer={false}/>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Offers;
