import { useQuery } from "@tanstack/react-query";
import { AXIOS_RESERVATION } from "../utils/axios-interceptors";
import { IUseGetCouponTypesQueryResponse } from "../utils/types";

const useGetCouponTypesQuery = (): IUseGetCouponTypesQueryResponse =>
  useQuery({
    queryKey: ["coupon-types"],
    queryFn: async (data) => {
      const response = await AXIOS_RESERVATION.get("v1/coupons/types", data);
      return response.data;
    },
  });

export default useGetCouponTypesQuery;
