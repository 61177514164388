import Header from "./header";
import Sidebar from "./sidebar";
import classNames from "classnames";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const onToggleSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    const bodyWrapper = document.getElementById("body-wrapper");
    if (isSidebarOpen) {
      sidebar!.style.transform = `translateX(-280px)`;
      bodyWrapper!.style.padding = "0";
    } else {
      sidebar!.style.transform = `translateX(0px)`;
      bodyWrapper!.style.paddingLeft = "280px";
      sidebar!.style.display = `block`;
    }
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="flex items-start min-h-screen">
      {/* Sidebar starts*/}
      <Sidebar />
      {/* Sidebar ends*/}
      {/* Page wrapper starts */}
      <div
        id="body-wrapper"
        className={classNames(
          "min-h-screen w-full bg-[#F4F6FD] transition-all",
          `pl-[280px]`
        )}
      >
        {/* Header starts */}
        <div className="h-[72px]">
          <Header onToggleSidebar={onToggleSidebar} />
        </div>
        {/* Header ends */}
        {/* Body starts */}
        <div className="">
          <Outlet />
        </div>
        {/* Body ends */}
      </div>
      {/* Page wrapper ends */}
    </div>
  );
};

export default Layout;
