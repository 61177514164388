import { useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { FaEnvelope, FaEye } from "react-icons/fa";
import useAdminLoginMutation from "../../../hooks/use-admin-login";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../../components/common/CheckBox";
import TextInput from "../../../components/common/TextInput";
import Button from "../../../components/common/button";
import { toast } from "react-toastify";

interface FormData {
  email: string;
  password: string;
  isRemember: boolean;
}

const Login = () => {
  const navigate = useNavigate();
  const [togglePassword, setTogglePassword] = useState<boolean>(false);

  const Methods = useForm<FormData>();

  const {
    handleSubmit,
  } = Methods;

  const { mutate: login, isPending } = useAdminLoginMutation();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const { email, password } = data;
    login(
      { email, password },
      {
        onSuccess: (res) => {
          if (res.result.token) {
            localStorage.setItem("token", res.result.token);
            navigate("/shmv/tv-app/room/lights");
          }
        },
        onError: (err) => {
          toast.error(err.response?.data?.result?.message);
        },
      }
    );
  };

  return (
    <div className="flex items-center justify-center h-screen bg-pink-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <div className="text-center mb-8">
          <img
            className="w-[180px] mx-auto mb-4"
            src="/images/shashi-logo.png"
            alt="Shashi-Logo"
          />
        </div>
        <FormProvider {...Methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {/* Email Address */}
            <TextInput
              label="Email Address"
              type="email"
              name="email"
              // register={register}
              placeholder="test@gmail.com"
              Icon={FaEnvelope}
              // error={errors.email}
              labelClasses="text-gray-700 font-medium"
              iconClasses="text-pink-500"
              inputClasses="w-full px-10 py-2 border rounded-md focus:outline-none"
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />

            {/* Password */}
            <TextInput
              label="Password"
              type="password"
              name="password"
              // register={register}
              placeholder="test@123"
              Icon={FaEye}
              // error={errors.password}
              labelClasses="text-gray-700 font-medium"
              iconClasses="text-pink-500"
              inputClasses="w-full px-10 py-2 border rounded-md focus:outline-none"
              rules={{
                required: "Password is required",
                minLength: {
                  value: 5,
                  message: "Password must be at least 6 characters",
                },
              }}
              togglePassword={() => setTogglePassword(!togglePassword)}
              showPassword={togglePassword}
            />

            {/* Remember Password */}
            <div className="flex items-center space-x-2">
              <Checkbox
                label="Remember password"
                name="rememberMe"
                // register={register}
                customClass="text-gray-500"
              />
            </div>

            {/* Updated Login Button with loader */}
            <div className="flex items-center justify-end pb-10">
              <Button
                variant="primary"
                className="w-24"
                disabled={isPending}
                loading={isPending}
                type="submit"
              >
                Login
              </Button>
            </div>
            {/* {isError && <p style={{ color: 'red' }}>{error.message}</p>} */}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Login;
