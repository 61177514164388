import classNames from "classnames";
import {
  SidebarGlobalSettingsSvg,
  SidebarHomeSvg,
  SidebarMasterUsersSvg,
  SidebarMobileAppSvg,
} from "../../icons";
import { useLocation, useParams } from "react-router";

const SIDEBAR_ITEMS = [
  {
    title: "TV App",
    icon: SidebarHomeSvg,
    badge: true,
    active: false,
    path: `/tv-app`,
  },
  {
    title: "Mobile App",
    icon: SidebarMobileAppSvg,
    badge: false,
    active: false,
    path: `/mobile-app`,
  },
  {
    title: "Master Users",
    icon: SidebarMasterUsersSvg,
    badge: false,
    active: false,
    path: `/master-users`,
  },
  {
    title: "Global Setting",
    icon: SidebarGlobalSettingsSvg,
    badge: false,
    active: false,
    path: `/global-setting`,
  },
];

const Sidebar = () => {
  const params = useParams();
  const location = useLocation();

  return (
    <div
      className="fixed h-full w-[280px] p-4 bg-white shadow-[0px_0px_21px_0px_#59667A1A] ease-in-out transition-all"
      id="sidebar"
    >
      <img
        className="h-7"
        src="/images/shashi-logo.png"
      />
      <div className="mt-7.5">
        {SIDEBAR_ITEMS.map((item) => {
          return (
            <div
              key={item.path}
              className={classNames(
                "flex items-center justify-between pl-[15px] pr-[30px] py-3 rounded-[10px] cursor-pointer hover:bg-[#F4F6FF] hover:text-[#F41095]",
                location.pathname.includes(
                  `/${params.hotelcode}${item.path}`
                ) || location.pathname === `/${params.hotelcode}${item.path}`
                  ? "bg-[#F4F6FF] text-[#F41095]"
                  : ""
              )}
            >
              <div className="flex items-center gap-[5px]">
                <item.icon />
                <span>{item.title}</span>
              </div>
              {item.badge && (
                <div className="px-2 py-1 bg-[#6362E71A] rounded-[10px] text-[10px] leading-[10px] font-normal tracking-[0.7px] text-[#6362E7]">
                  2
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
